import * as validator from 'email-validator';

$(function() {
    let lookup = false;
    let $i_clear_multi = [];
    const tbl = 'tco_drv';
    const name = app.TBL[tbl].i;
    const f = 'tco_user';
    let $i_clear = null,
            $i_search = null,
            $i_loading = null;

    app.init_tco_mis_mil_autocomplete = (modalEl) => {
        lookup = false;
        // cache dom elements
        app.DOM.tco_user = modalEl;
        app.DOM.tco_user_id = modalEl.find(`#${f}_id`);
        app.DOM.tco_user_name = modalEl.find('#'+f+'_name').addClass('ui-autocomplete-input');

        // add btn clear and cache
        const html = `
            <i class="btn-autocomplete-clear btn-autocomplete-clear-${tbl} icon fa fa-times" style="margin-top:7px;"></i>
            <i class="autocomplete-loading autocomplete-loading-${tbl} icon fad fa-spin fa-spinner-third"></i>
            <i class="autocomplete-search autocomplete-search-${tbl} icon fa fa-search" style="margin-top:7px;"></i>
        `;

        // add container class
        app.DOM.tco_user_name.after(html).parent().parent().addClass('autocomplete-container');
        $i_clear = modalEl.find(`.btn-autocomplete-clear-${tbl}`);
        $i_search = modalEl.find(`.autocomplete-search-${tbl}`);
        $i_loading = modalEl.find(`.autocomplete-loading-${tbl}`);

        // check if input already has saved input
        if( app.DOM.tco_user_name.length === 1 ) {
            if( app.DOM.tco_user_name.val() === '' ) {
                showSearch();
            } else {
                showClear();
            }
        }

        // load accounts from ajax
        app.DOM.tco_user_name.on('focus', (e) => {
            setupLookup(e.type);
        }).on('blur', function(){
            if( app.DOM.tco_user_id.val() === '' ){
                app.DOM.tco_user_name.val('');
                showSearch();
            }
        }).on('keyup', (e) => {
            if(e.value === '') {
                showSearch();
                app.DOM.tco_user_id.val('').trigger('change');
            } else {
                showClear();
            }
        })

        if (app.OPTIONS.autocomplete_usr_email) {
            app.DOM.tco_user_name.on('keydown', (e) => {
                if (e.keyCode === 13) {
                    // Enter key - add whatever's in the box as if it were a valid email
                    if (validator.validate(e.value)) {
                        selectAuto(e, {item:{
                            value: e.value,
                            label: `Email: ${e.value}`,
                        }})
                        $(e.currentTarget).removeClass('error');
                    } else {
                        e.preventDefault()
                        $(e.currentTarget).addClass('error');
                    }
                } else {
                    $(e.currentTarget).removeClass('error');
                }
            });
        }

        // press clear button
        $i_clear.unbind('click').on('click', () => {

            // confirm account
            if( app.DOM.tco_user_name.hasClass('disabled') || ( app.OPTIONS.form && confirm("Remove?") === false ) ) {
                console.log('cant clear');
                return;
            }

            showSearch();
            app.DOM.tco_user_id.val('').trigger('change');
            app.DOM.tco_user_name.val('').focus();
        });

        if( app.OPTIONS.autocomplete_usr_multi ) {
            setupMultiDelete();
        }

        setTimeout(() => {
            setupLookup('focus');
        }, 150);
    }

    function showClear()
    {
        $i_clear.show();
        $i_loading.hide();
        $i_search.hide();
    }

    function showLoading()
    {
        $i_clear.hide();
        $i_loading.show();
        $i_search.hide();
        app.DOM.tco_user_name.prop('readonly', true);
    }

    function showSearch()
    {
        $i_clear.hide();
        $i_loading.hide();
        $i_search.show();
        app.DOM.tco_user_name.prop('readonly', false);
    }

    function selectAutoMulti(item)
    {
        // clear selection
        app.DOM.tco_user_name.val('');

        // check already added
        if( $i_clear_multi.filter(`[data="${tbl}_${item.value}"]`).length > 0 ){
            alert('This '+name +' has already been added');
            return false;
        }

        const multiHtml = `<span class="autocomplete-multi">
        <input type="hidden" name="${f}[${item.value}]">
        ${item.label}
        <i class="fa fa-times btn-autocomplete-clear-multi-${tbl}" title="Remove selection" data="${tbl}_${item.value}"></i>
        </span>`;
                    
        // add html
        $i_clear.after(multiHtml);

        // re-cache
        setupMultiDelete();
        
        return true;
    }

    function checkMultiHasValues()
    {
        const $inputs = app.DOM.form.find(`input:hidden[name*="${f}["]`);

        if( $inputs.length ) {
          app.DOM.tco_user_name.removeClass('required');
        } else {
          app.DOM.tco_user_name.addClass('required');
        }
     }

    var selectAuto = function(e, ui){

        // prevent jquery default
        if( e ) {
            e.preventDefault();
        }

        // update dom
        app.DOM.tco_user_id.val(ui.item.value).trigger('change');
        app.DOM.tco_user_name.val(ui.item.label);
        // change buttons
        showClear();
    };
    
    function setupMultiDelete()
    {
        $i_clear_multi = app.DOM[container].find(`.btn-autocomplete-clear-multi-${tbl}`);
        
        $i_clear_multi.unbind().on('click', function(){
            if( app.OPTIONS.form && confirm("Remove?") === false ) {
                return;
            }
            
            $(this).closest('span.autocomplete-multi').remove();
            $i_clear_multi = app.DOM[container].find(`.btn-autocomplete-clear-multi-${tbl}`);


            checkMultiHasValues();
        });

        // check initial state
        if( app.FORM.action === 'edit' && (app.URI[1] !== 'msg' || app.DOM.form.find('#type').val() === 'usr') ) {
            checkMultiHasValues();
        }
    }
    
    function setupAutocomplete(result)
    {
        const minLength = ( result.length <= 10 ) ? 0 : 1;
        app.DOM.tco_user_name.autocomplete({
            minLength: minLength,
            source: result,
            select: selectAuto,
            focus: function(e, ui){
                e.preventDefault();
            }
        });

        if( minLength === 0 ) {
            app.DOM.tco_user_name.on('focus', function(){
                if(!this.value){
                    $(this).autocomplete('search', '');
                }
            })
        }
    }
    
    function getAjaxData()
    {
        let data = {
            tbl_ref: app.URI[1],
            type:  (app.OPTIONS.form ) ? 'form' : app.URI[1]
        };

        if( app.OPTIONS.dashboard ) {
            data.tbl_ref = app.OPTIONS.dashboard;
            data.type = 'dashboard';
        } else if( app.URI[1] === 'list' || app.URI[1] === 'planner' ) {
            data.tbl_ref = app.URI[2];
        }

        // references purposes
        if( $.isNumeric(app.URI[2]) ){
            data.id = app.URI[2];
        }
        
        if( app.URI[1] === tbl ) {            
            if( $.isNumeric(app.URI[2]) ){
                data.exclude_id = app.URI[2];
            }
        }
        
        return data;
    }
    
    // select user from uri
    function selectFromURI(json, id)
    {
        var index = false;
        
        $.each(json, function(k,v){
            if( v.value === id){
                index = k;
                return false;
            }
        });
        
        // do nothing
        if( index === false ) {
            return;
        }
        
        var data = {
            item: {
                value: json[index].value,
                label: json[index].label
            }
        };
        
        selectAuto(false, data);
    }

    function setupLookup(type)
    {
        if( type === 'focus' && !lookup ) {
            showLoading();
        }

        if(lookup) {
            return;
        }

        app.DOM.tco_user_name = app.DOM.tco_user.find('#'+f+'_name').addClass('ui-autocomplete-input');
        // lookup accounts
        var ajax = $.ajax({
            url: `${app.CACHE.URL_AJAX + tbl}/get`,
            method: 'POST',
            data: getAjaxData()
        });

        ajax.done( r => {

            if(app.DOM.tco_user_name.val() && app.DOM.tco_user_id.val()) {
                showClear();
            } else {
                showSearch();
            }

            // success
            if( r.status && r.status === 'success' && r.data ) {
                // mark lookup complete
                lookup = true;
                // init autocomplete
                setupAutocomplete(r.data);

                if( app.URI[2] === 'false' && app.get_filter_param(3, false) === 'usr' && $.isNumeric(app.get_filter_param(3)) ){

                    // admin/tbl/false/usr:123
                    selectFromURI(r.data, app.get_filter_param(3));

                } else if( app.URI[2] === 'false' && app.get_filter_param(4, false) === 'usr' && $.isNumeric(app.get_filter_param(4)) ){

                    // admin/tbl/false/xxx/usr:123
                    selectFromURI(r.data, app.get_filter_param(4));
                    
                } else if( app.URI[2] === 'false' && app.URI[3] === 'usr' && $.isNumeric(4) ){

                    // admin/tbl/false/usr/123
                    selectFromURI(r.data, 4);
                }
            }
        });
    }
});